import clsx from 'clsx'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

const PillTab = ({
	title,
	leftIcon,
	rightIcon,
	selected,
	onClick,
	disabled = false,
	size = 'default',
}: {
	title: string
	leftIcon?: any
	rightIcon?: any
	selected?: boolean
	onClick?: () => void
	disabled?: boolean
	size?: string
}) => {
	// By default show PillTab
	let classes = clsx(
		'flex items-center flex-none whitespace-nowrap',
		//Default
		'py-1 px-4 text-labelLargeBold rounded-full',
		'border-solid border border-transparent',
		size === 'default' ? 'text-labelLargeBold' : 'text-labelMediumBold',
		//Hover
		`${!selected && 'hover:text-textPrimaryLight hover:border-textInactiveLight'}`,
		`${!selected && 'dark:hover:text-textPrimaryDark dark:hover:border-textInactiveDark'}`,
		//Selected
		`${selected ? 'text-textPrimaryDark bg-black' : 'text-textSecondaryLight'}`,
		`${selected ? 'dark:text-textPrimaryLight dark:bg-white' : 'dark:text-textSecondaryDark'}`,
		//Disabled
		'disabled:text-textInactiveLight disabled:border-transparent',
		'dark:disabled:border-transparent dark:disabled:text-textInactiveDark',
	)

	return (
		<button
			className={classes}
			onClick={e => onClick && onClick()}
			disabled={disabled}
		>
			<div className="flex w-full flex-row items-center justify-center gap-x-2">
				{leftIcon}
				{title}
				{rightIcon}
			</div>
		</button>
	)
}

export default PillTab
